import Phaser from 'phaser'
import BootScene from './scenes/BootScene'
import PlayScene from './scenes/PlayScene'

function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    width: 500,
    height: 700,
    parent: containerId,
    physics: {
      default: 'matter',
      matter: {
        debug: false
      }
    },
    scene: [BootScene, PlayScene],
    fps: { target: 60, min: 30 },
    transparent: true,
  })
}

export default launch
export { launch }

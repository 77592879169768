import { Scene } from 'phaser'

import spritesSheet15 from '@/game/assets/sprites_15.png'
import spritesJson15 from '@/game/assets/sprites_15.json'
import spritesShapes15 from '@/game/assets/shapes_15.json'

import soundFlipper from '@/game/assets/sounds/mp3/thud.mp3'

import soundBg from '@/game/assets/sounds/mp3/bg.mp3'
import soundNewGame from '@/game/assets/sounds/mp3/new_game.mp3'
import soundBump from '@/game/assets/sounds/mp3/bump.mp3'
import soundOutlineBump from '@/game/assets/sounds/mp3/outline.mp3'
import soundGameOver from '@/game/assets/sounds/mp3/game_over.mp3'

export default class BootScene extends Scene {
  constructor() {
    super({ key: 'BootScene' })
  }

  preload() {
    this.load.atlas('sheet', spritesSheet15, spritesJson15)
    this.load.json('shapes', spritesShapes15)

    this.load.audio('flippers', [soundFlipper])

    this.load.audio('bg', [soundBg])
    this.load.audio('new_game', [soundNewGame])
    this.load.audio('bump', [soundBump])
    this.load.audio('outline_bump', [soundOutlineBump])
    this.load.audio('game_over', [soundGameOver])
  }

  create() {
    this.scene.start('PlayScene')
  }
}

import { Scene } from 'phaser'

import Ball from '../objects/Ball'
import Bouncer from '../objects/Bouncer'
import Indicator from '../objects/Indicator'

import gameConfig from '../config'
import store from '../../store'

let lifes
let points

const config = store.state.isMobile ? gameConfig.desktop : gameConfig.desktop

const SCALE_FACTOR = config.gameScaleFactor
const INDICATOR_SCALE_FACTOR = config.indicatorScaleFactor
const BALL_SCALE_FACTOR = config.ballScaleFactor

let stucked = false

export default class PlayScene extends Scene {
  constructor() {
    super({ key: 'PlayScene' })
  }

  create() {
    let center = {
      x: this.sys.game.config.width / 2,
      y: this.sys.game.config.height / 2
    }

    var shapes = this.cache.json.get('shapes')
    this.matter.world.setBounds(0, 0, this.sys.game.config.width, this.sys.game.config.height)

    // this.matter.world.engine.positionIterations = 30
    // this.matter.world.engine.velocityIterations = 30

    let bg = this.add.image(center.x, center.y, 'sheet', 'bg_full.png')
    bg.displayWidth = bg.width * SCALE_FACTOR
    bg.displayHeight = bg.height * SCALE_FACTOR

    var outline = this.matter.add.sprite(center.x + 10, center.y, 'sheet', 'outline.png', { shape: shapes.outline })
    outline.displayWidth = outline.width * SCALE_FACTOR
    outline.displayHeight = outline.height * SCALE_FACTOR

    this.sound.play('bg', {
      volume: 0.2,
      loop: true
    })

    this.indicators = [
      new Indicator(this, config.position.logo, Indicator.INDICATOR_TYPE.LOGO, shapes, INDICATOR_SCALE_FACTOR),
      new Indicator(this, config.position.arrowRed, Indicator.INDICATOR_TYPE.ARROW_RED, shapes, INDICATOR_SCALE_FACTOR),
      new Indicator(this, config.position.arrowBlue, Indicator.INDICATOR_TYPE.ARROW_BLUE, shapes, INDICATOR_SCALE_FACTOR),
      new Indicator(this, config.position.arrowYellow, Indicator.INDICATOR_TYPE.ARROW_YELLOW, shapes, INDICATOR_SCALE_FACTOR)
    ]

    this.bouncers = [
      new Bouncer(this, config.position.planet1, Bouncer.BOUNCER_TYPE.PLANET1, shapes, SCALE_FACTOR),
      new Bouncer(this, config.position.planet2, Bouncer.BOUNCER_TYPE.PLANET2, shapes, SCALE_FACTOR),
      new Bouncer(this, config.position.planet3, Bouncer.BOUNCER_TYPE.PLANET3, shapes, SCALE_FACTOR),

      new Bouncer(this, config.position.planet4, Bouncer.BOUNCER_TYPE.PLANET2, shapes, SCALE_FACTOR),
      new Bouncer(this, config.position.planet5, Bouncer.BOUNCER_TYPE.PLANET1, shapes, SCALE_FACTOR),

      new Bouncer(this, config.position.bouncerLeft, Bouncer.BOUNCER_TYPE.BOUNCER_LEFT, shapes, SCALE_FACTOR),
      new Bouncer(this, config.position.bouncerRight, Bouncer.BOUNCER_TYPE.BOUNCER_RIGHT, shapes, SCALE_FACTOR),

      new Bouncer(this, config.position.railLeft, Bouncer.BOUNCER_TYPE.RAIL_LEFT, shapes, SCALE_FACTOR),
      new Bouncer(this, config.position.railRight, Bouncer.BOUNCER_TYPE.RAIL_RIGHT, shapes, SCALE_FACTOR)
    ]

    this.ball = new Ball(this, config.position.ball, shapes, BALL_SCALE_FACTOR)

    const leftFlipper = this.matter.add.sprite(config.position.flipperLeft.x, config.position.flipperLeft.y, 'sheet', 'trigger_left.png', { shape: shapes.trigger_left })
    leftFlipper.displayWidth = leftFlipper.width * SCALE_FACTOR
    leftFlipper.displayHeight = leftFlipper.height * SCALE_FACTOR
    leftFlipper.angle = -15
    leftFlipper.isRising = false
    leftFlipper.setBounce(0.6)
    leftFlipper.body.restitution = 0.5

    this.leftFlipper = leftFlipper

    const rightFlipper = this.matter.add.sprite(config.position.flipperRight.x, config.position.flipperRight.y, 'sheet', 'trigger_right.png', { shape: shapes.trigger_right })
    rightFlipper.displayWidth = rightFlipper.width * SCALE_FACTOR
    rightFlipper.displayHeight = rightFlipper.height * SCALE_FACTOR
    rightFlipper.angle = 15
    rightFlipper.isRising = false
    rightFlipper.setBounce(0.6)
    rightFlipper.body.restitution = 0.5

    this.rightFlipper = rightFlipper

    this.A_key = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A)
    this.D_key = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D)
    this.P_key = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.P)
    this.cursors = this.input.keyboard.createCursorKeys()

    this.pointer1 = this.input.pointer1
    this.pointer2 = this.input.pointer2

    this.leftPressed = false
    this.rightPressed = false

    this.tweenflip1 = null
    this.tweenflip2 = null

    this.indicators.forEach(indicator => {
      indicator.body.parts[1].setOnCollideWith(this.ball.body.parts[1], pair => indicator.beenHit())
    })

    this.bouncers.forEach(bouncer => {
      bouncer.body.parts[1].setOnCollideWith(this.ball.body.parts[1], pair => {

        this.sound.play('bump', { volume: 0.3 })
        this.updateScore(bouncer.beenHit())
      })
    })

    this.leftFlipper.body.parts[1].setOnCollideWith(this.ball.body.parts[1], pair => {
      this.ball.setVelocity(
        this.ball.body.velocity.x * 1.2,
        this.leftFlipper.isRising ? -20 : this.ball.body.velocity.y * 2.5,
      )
    })

    this.rightFlipper.body.parts[1].setOnCollideWith(this.ball.body.parts[1], pair => {
      this.ball.setVelocity(
        this.ball.body.velocity.x * 1.2,
        this.rightFlipper.isRising ? -20 : this.ball.body.velocity.y * 2.5,
      )
    })

    this.resetGame()
  }

  update() {
    this.constrainVelocity(this.ball, this.ball.body.velocity)

    if (this.ball.body.velocity.x == 0 && this.ball.body.velocity.y == 0) {
      stucked = true
      setTimeout(() => {
        if (stucked) this.resetPosition()
      }, 200)
    } else {
      stucked = false
    }

    if (this.sys.game.input.mousePointer.isDown) {
      console.log(this.sys.game.input.mousePointer.x, this.sys.game.input.mousePointer.y)
    }

    const tweenDuration = 45
    const upAngle = 45
    const lowAngle = -10

    const screenCenter = this.sys.game.config.width / 2

    let isPressingLeft = false
    let isPressingRight = false

    if (this.pointer1 && this.pointer2) {
      isPressingLeft = (this.pointer1.isDown && this.pointer1.x < screenCenter) || (this.pointer2.isDown && this.pointer2.x < screenCenter)
      isPressingRight = (this.pointer1.isDown && this.pointer1.x > screenCenter) || (this.pointer2.isDown && this.pointer2.x > screenCenter)
    }

    if ((this.A_key.isDown || this.cursors.left.isDown || isPressingLeft) && this.leftPressed == false) {
      this.leftPressed = true

      if (this.tweenflip1) this.tweenflip1.stop()

      this.tweenflip1 = this.tweens.add({
        targets: this.leftFlipper,
        angle: -upAngle,
        duration: tweenDuration,
        ease: 'Linear',
        onStart: () => {
          this.leftFlipper.isRising = true
        },
        onComplete: () => {
          this.leftFlipper.isRising = false
        }
      })
      this.sound.play('flippers', { volume: 0.3 })
    }

    if ((this.D_key.isDown || this.cursors.right.isDown || isPressingRight) && this.rightPressed == false) {
      this.rightPressed = true

      if (this.tweenflip2) this.tweenflip2.stop()

      this.tweenflip2 = this.tweens.add({
        targets: this.rightFlipper,
        angle: upAngle,
        duration: tweenDuration,
        ease: 'Linear',
        onStart: () => {
          this.rightFlipper.isRising = true
        },
        onComplete: () => {
          this.rightFlipper.isRising = false
        }
      })
      this.sound.play('flippers', { volume: 0.3 })
    }

    if ((!this.A_key.isDown && !this.cursors.left.isDown && !isPressingLeft) && (this.leftPressed == true)) {
      this.leftPressed = false

      if (this.tweenflip1) this.tweenflip1.stop()

      this.tweenflip1 = this.tweens.add({
        targets: this.leftFlipper,
        angle: -lowAngle,
        duration: tweenDuration,
        ease: 'Linear'
      })
    }

    if ((!this.D_key.isDown && !this.cursors.right.isDown && !isPressingRight) && (this.rightPressed == true)) {
      this.rightPressed = false

      if (this.tweenflip2) this.tweenflip2.stop()

      this.tweenflip2 = this.tweens.add({
        targets: this.rightFlipper,
        angle: lowAngle,
        duration: tweenDuration,
        ease: 'Linear'
      })
    }

    // if (this.P_key.isDown) this.resetPosition()

    if (this.ball.x > 397 && this.ball.x < 425 && this.ball.y > 620) {
      this.resetBall()
    }

    if (this.ball.y > 670) {
      lifes--

      if (lifes >= 0) {
        store.commit('SET_LIFES', lifes)
        this.resetBall()
      } else {
        store.commit('SET_GAME_OVER', true)
      }
    }
  }

  resetBall() {
    this.resetPosition()
    this.sound.play('game_over', { volume: 0.3 })
    this.updateScore(100)
  }

  updateScore(addPoints) {
    points += addPoints
    store.commit('SET_SCORE', points)
  }

  resetGame() {
    this.resetPosition()

    lifes = 4
    points = 0

    store.commit('RESET_GAME', { points, lifes })
  }

  resetPosition() {
    this.ball.setVelocityY(-30)
    this.ball.x = config.startPosition.x
    this.ball.y = config.startPosition.y
  }

  constrainVelocity(object, velocity) {
    const limit = 35

    if (Math.abs(velocity.x) > limit) {
      this.ball.setVelocityX(Math.min(Math.max(parseInt(velocity.x), -limit), limit))
    }

    if (Math.abs(velocity.y) > limit) {
      this.ball.setVelocityY(Math.min(Math.max(parseInt(velocity.y), -limit), limit))
    }
  }
}

export default {
  desktop: {
    gameScaleFactor: 0.6,
    ballScaleFactor: 0.7,
    indicatorScaleFactor: 0.3,
    startPosition: { x: 408, y: 400 },
    position: {
      logo: { x: 236, y: 306 },
      arrowRed: { x: 410, y: 475 },
      arrowBlue: { x: 410, y: 332 },
      arrowYellow: { x: 410, y: 200 },
      planet1: { x: 232, y: 244 },
      planet2: { x: 310, y: 252 },
      planet3: { x: 330, y: 180 },
      planet4: { x: 310, y: 400 },
      planet5: { x: 165, y: 420 },
      bouncerLeft: { x: 155, y: 520 },
      bouncerRight: { x: 315, y: 520 },
      railLeft: { x: 120, y: 540 },
      railRight: { x: 352, y: 534 },
      flipperLeft: { x: 194, y: 598 },
      flipperRight: { x: 275, y: 598 },
      ball: { x: 430, y: 640 }
    }
  },
  mobile: {
    gameScaleFactor: 0.4,
    ballScaleFactor: 0.5,
    indicatorScaleFactor: 0.15,
    startPosition: { x: 356, y: 526 },
    position: {
      logo: { x: 236, y: 306 },
      arrowRed: { x: 410, y: 475 },
      arrowBlue: { x: 410, y: 332 },
      arrowYellow: { x: 410, y: 200 },
      planet1: { x: 232, y: 244 },
      planet2: { x: 310, y: 252 },
      planet3: { x: 330, y: 180 },
      planet4: { x: 310, y: 400 },
      planet5: { x: 165, y: 420 },
      bouncerLeft: { x: 155, y: 520 },
      bouncerRight: { x: 300, y: 406 },
      railLeft: { x: 120, y: 540 },
      railRight: { x: 352, y: 534 },
      flipperLeft: { x: 194, y: 598 },
      flipperRight: { x: 275, y: 598 },
      ball: { x: 430, y: 640 }
    }
  }
}
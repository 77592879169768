import Phaser from 'phaser'

export default class Bouncer extends Phaser.Physics.Matter.Sprite {
  static BOUNCER_TYPE = {
    PLANET1: {
      idle_frame: 'planet_1',
      points: 5,
      enabled: true
    },
    PLANET2: {
      idle_frame: 'planet_2',
      points: 10,
      enabled: true
    },
    PLANET3: {
      idle_frame: 'planet_3',
      points: 30,
      enabled: true
    },
    BOUNCER_LEFT: {
      idle_frame: 'bouncer_left',
      points: 5,
      enabled: true
    },
    BOUNCER_RIGHT: {
      idle_frame: 'bouncer_right',
      points: 5,
      enabled: true
    },
    RAIL_LEFT: {
      idle_frame: 'rail_left',
      points: 5,
      enabled: false
    },
    RAIL_RIGHT: {
      idle_frame: 'rail_right',
      points: 5,
      enabled: false
    }
  }

  constructor(scene, position, type = this.BOUNCER_TYPE.PLANET1, shapes, scaleFactor = 1) {
    super(scene.matter.world, position.x, position.y, 'sheet', type.idle_frame + '.png', { shape: shapes[type.idle_frame] })

    scene.add.existing(this)
    this.setBounce(0.8)
    this.body.restitution = 0.9

    this.displayWidth = this.width * scaleFactor
    this.displayHeight = this.height * scaleFactor

    this.type = type
    this.points = type.points
  }

  beenHit() {
    if (this.type.enabled) {
      this.setFrame(this.type.idle_frame + '_active.png')

      setTimeout(() => {
        this.setFrame(this.type.idle_frame + '.png')
      }, 150)
    }
    
    return this.points
  }
}
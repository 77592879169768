import Phaser from 'phaser'

export default class Indicator extends Phaser.Physics.Matter.Sprite {
  static INDICATOR_TYPE = {
    LOGO: {
      idle_frame: 'logo'
    },
    ARROW_YELLOW: {
      idle_frame: 'arrow_yellow'
    },
    ARROW_BLUE: {
      idle_frame: 'arrow_blue'
    },
    ARROW_RED: {
      idle_frame: 'arrow_red'
    }
  }

  constructor(scene, position, type = this.INDICATOR_TYPE.LOGO, shapes, scaleFactor = 1) {
    super(scene.matter.world, position.x, position.y, 'sheet', type.idle_frame + '.png', { shape: shapes[type.idle_frame] })

    scene.add.existing(this)

    this.displayWidth = this.width * scaleFactor
    this.displayHeight = this.height * scaleFactor

    this.type = type
  }

  beenHit() {
    this.setFrame(this.type.idle_frame + '_active.png')

    setTimeout(() => {
      this.setFrame(this.type.idle_frame + '.png')
    }, 500)
  }
}
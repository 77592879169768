import Phaser from 'phaser'

export default class Ball extends Phaser.Physics.Matter.Sprite {
  constructor(scene, position, shapes, scaleFactor = 1) {
    super(scene.matter.world, position.x, position.y, 'sheet', 'ball.png', { shape: shapes.ball })

    scene.add.existing(this)

    this.body.label = 'ball'
    this.displayWidth = this.width * scaleFactor
    this.displayHeight = this.height * scaleFactor

    this.setFrictionAir(0.005);
    this.setBounce(0.8)
  }
}